<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 13.8333V4C21 2.89543 20.1046 2 19 2H5C3.89543 2 3 2.89543 3 4V8C3 9.10457 3.89543 10 5 10H17"
      stroke="#273340"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="7"
      y1="14"
      x2="17"
      y2="14"
      stroke="#0080FF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="7"
      y1="6"
      x2="13"
      y2="6"
      stroke="#0080FF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="7"
      y1="18"
      x2="17"
      y2="18"
      stroke="#0080FF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M3 14.0002V20.0002C3 21.1048 3.89543 22.0002 5 22.0002H19C20.1046 22.0002 21 21.1048 21 20.0002V13.1113"
      stroke="#273340"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle cx="17" cy="6" r="1" fill="#0080FF" />
  </svg>
</template>
